import _ from 'lodash';
import featureFlag from '@/api/featureFlag';
import Segment from '@/Segment';
import DDLogs from '@/DDLogs';
import config from '@/config';
import reactiveCashflow from '@riseupil/reactive-cashflow';
import consts from '../utilities/constants';

const state = {
  featureFlags: {},
  remoteVariables: {},
  featureFlagsJsons: {},
  localOverrides: {},
};

const LOCAL_STORAGE_OVERRIDE_PREFIX = 'feature_flag_';
const { FEATURE_FLAG_NAMES: featureFlagNames, REMOTE_VARIABLES: remoteVariables, FEATURE_FLAG_JSON_NAMES: featureFlagJsonNames } = consts;

const getters = {
  featureFlagAttributes: state => state.featureFlags,
  isStoryMenuOn: state => _isFlagOn(state, featureFlagNames.STORY_MENU),
  showTrackingCategoryChallenge: state => _isFlagOn(state, featureFlagNames.TRACKING_CATEGORY_CHALLENGE),
  keepAmbassadorsInApp: state => _isFlagOn(state, featureFlagNames.JOIN_AMBASSADORS_LINK),
  saveStoryShare: state => _isFlagOn(state, featureFlagNames.SAVE_STORY_SHARE),
  buildsFixedIncomeUsingMLModel: state => _isFlagOn(state, featureFlagNames.FIXED_INCOME_USING_ML_MODEL),
  shouldSharePreviewImages: state => _isFlagOn(state, featureFlagNames.SHARE_PREVIEW_IMAGES),
  moneyBackGuaranteeIsOn: state => _isFlagOn(state, featureFlagNames.MONEY_BACK_GUARANTEE_TEST),
  noTrialDiscountIsOn: state => _isFlagOn(state, featureFlagNames.NO_TRIAL_DISCOUNT),
  migrationFromPasswords: state => _isFlagOn(state, featureFlagNames.MIGRATION_FROM_PASSWORDS),
  israelBankInterest: state => parseFloat(_getVariableValue(state, remoteVariables.ISRAEL_BANK_INTEREST)),
  inflationRate: state => parseFloat(_getVariableValue(state, remoteVariables.INFLATION_RATE)),
  isFlagIsOn: state => _isFlagOn.bind(this, state),
  showEditTrackingCategoryName: state => _isFlagOn(state, featureFlagNames.SHOW_EDIT_TRACKING_CATEGORY_NAME),
  showExperimentalPages: state => _isFlagOn(state, featureFlagNames.AKIVA_STYLE),
  enableChangeCashflowStartDay: state => _isFlagOn(state, featureFlagNames.ENABLE_CHANGE_BUDGET_START_DAY),
  jitTechnicalError: state => _isFlagOn(state, featureFlagNames.JIT_TECHNICAL_ERROR),
  jitNegativeBalancePositiveCf: state => _isFlagOn(state, featureFlagNames.JIT_NEGATIVE_BALANCE_POSITIVE_CF),
  jitSubscriptionPopup: state => _isFlagOn(state, featureFlagNames.JIT_SUBSCRIPTION_POPUP),
  jitUpdateCredentials: state => _isFlagOn(state, featureFlagNames.JIT_UPDATE_CREDENTIALS),
  jitBankCcBug: state => _isFlagOn(state, featureFlagNames.JIT_BANK_CC_BUG),
  jitLoadingCredentials: state => _isFlagOn(state, featureFlagNames.JIT_LOADING_CREDENTIALS),
  jitInviteMember: state => _isFlagOn(state, featureFlagNames.JIT_INVITE_MEMBER),
  jitMergeInvestigation: state => _isFlagOn(state, featureFlagNames.JIT_MERGE_INVESTIGATION),
  jitPapasMerge: state => _isFlagOn(state, featureFlagNames.JIT_PAPAS_MERGE),
  jitHighIncomeExclusion: state => _isFlagOn(state, featureFlagNames.JIT_HIGH_INCOME_EXCLUSION),
  jitChurn: state => _isFlagOn(state, featureFlagNames.JIT_CHURN),
  jitConsentFailureRetry: state => _isFlagOn(state, featureFlagNames.JIT_CONSENT_FAILURE_RETRY),
  jitConsentFailure: state => _isFlagOn(state, featureFlagNames.JIT_CONSENT_FAILURE),
  excludedSavingsTransactions: state => _isFlagOn(state, featureFlagNames.EXCLUDED_SAVINGS_TRANSACTION),
  cashflowTabsEnabled: state => _isFlagOn(state, featureFlagNames.CASHFLOW_TABS),
  planAheadOnboardingPreCreds: state => state.featureFlags[featureFlagNames.PLAN_AHEAD_ONBOARDING] === 'after-forward-looking',
  planAheadOnboardingPostCreds: state => state.featureFlags[featureFlagNames.PLAN_AHEAD_ONBOARDING] === 'after-osh-balance-information',
  partialScrapeEnabled: state => _isFlagOn(state, featureFlagNames.PARTIAL_SCRAPE_ENABLED),
  mbgEnableYearlySubscription: state => _isFlagOn(state, featureFlagNames.MBG_ENABLE_YEARLY_SUBSCRIPTION),
  annualSubscriptionCopyTestForDormants: state => _isFlagOn(state, featureFlagNames.ANNUAL_SUBSCRIPTION_DORMANTS),
  showPlanAheadIntroPopup: state => _isFlagOn(state, featureFlagNames.PLAN_AHEAD_INTRODUCTION_POPUP),
  enablePlanAheadAddExistingFund: state => _isFlagOn(state, featureFlagNames.PLAN_AHEAD_EXISTING_FUND),
  showFinancialWarSection: state => _isFlagOn(state, featureFlagNames.FINANCIAL_WAR_SECTION),
  showAmbassadorsMenuBannerSection: state => _isFlagOn(state, featureFlagNames.AMBASSADORS_MENU_BANNER),
  enableWhatsappAlertsSettings: state => _isFlagOn(state, featureFlagNames.WHATSAPP_ALERTS_SETTINGS),
  advisoryInsightNewVariant: state => _isFlagOn(state, featureFlagNames.ADVISORY_INSIGHT_NEW_VARIANT),
  showCashflowSearch: state => _isFlagOn(state, featureFlagNames.CASHFLOW_SEARCH),
  showMislakaConsentScreen: state => _isFlagOn(state, featureFlagNames.SHOW_MISLAKA_CONSENT_SCREEN),
  disableElsaAnimations: state => _isFlagOn(state, featureFlagNames.DISABLE_ELSA_ANIMATIONS),
  newShortTermDormant: state => _isFlagOn(state, featureFlagNames.NEW_SHORT_TERM_DORMANT),
  eyo2023Story: state => _isFlagOn(state, featureFlagNames.EOY_2023_STORY),
  advisoryStory: state => _isFlagOn(state, featureFlagNames.ADVISORY_STORY),
  contestStory: state => _isFlagOn(state, featureFlagNames.CONTEST_STORY),
  newSubscriptionCta: state => _isFlagOn(state, featureFlagNames.NEW_SUBSCRIPTION_CTA),
  showMoneyMoonV2StoryInCashflowFF: state => _isFlagOn(state, featureFlagNames.SHOW_MONEY_MOONV2_STORY_IN_CASHFLOW),
  useNewChangeCategoryRadioList: state => _isFlagOn(state, featureFlagNames.CHANGE_CATEGORY_RADIO_LIST),
  showMyProgress: state => _isFlagOn(state, featureFlagNames.MY_PROGRESS),
  offeringsExperiment: state => state.featureFlagsJsons[featureFlagJsonNames.OFFERINGS_EXPERIMENT]?.offerings ?? [],
  showAdvisoryCtaInPlanAheadPage: state => state.featureFlags[featureFlagNames.PLAN_AHEAD_ADVISORY_CTA] === 'plans-page',
  showAdvisoryCtaAfterNewPlanAdded: state => state.featureFlags[featureFlagNames.PLAN_AHEAD_ADVISORY_CTA] === 'after-plan-popup',
  showAdvisoryCtaMyProgress: state => _isFlagOn(state, featureFlagNames.MY_PROGRESS_ADVISORY_CTA),
  showTransactionOriginalAmount: state => _isFlagOn(state, featureFlagNames.SHOW_TRANSACTION_ORIGINAL_AMOUNT),
  showAdvisorySingleMenuBanner: state => _isFlagOn(state, featureFlagNames.ADVISORY_SINGLE_MENU_BANNER),
  showMyProgressMenuBanner: state => _isFlagOn(state, featureFlagNames.MY_PROGRESS_MENU_BANNER),
  enableMoveTransactionsByPaymentAppAddressee: state => _isFlagOn(state, featureFlagNames.ENABLE_MOVE_TRANSACTIONS_BY_PAYMENT_APP_ADDRESSEE),
  showSuggestOfferingsPopup: state => _isFlagOn(state, featureFlagNames.SHOW_SUGGEST_OFFERINGS_POPUP),
  incognitoModeDetection: state => _isFlagOn(state, featureFlagNames.INCOGNITO_MODE_DETECTION),
  incognitoModeDetectionAmplitudeEvents: state => _isFlagOn(state, featureFlagNames.INCOGNITO_MODE_DETECTION_AMPLITUDE_EVENTS),
  openForEmployees: state => _isFlagOn(state, featureFlagNames.OPEN_FOR_EMPLOYEES),
  useNewCredNameText: state => _isFlagOn(state, featureFlagNames.NEW_CRED_NAME_INPUT),
  enableShareTransaction: state => _isFlagOn(state, featureFlagNames.SHARE_TRANSACTION),
  preventPoalimClosedBanking: state => _isFlagOn(state, featureFlagNames.PREVENT_POALIM_CLOSED_BANKING),
  openMeitavOBK: state => _isFlagOn(state, featureFlagNames.OPEN_MEITAV_OBK),
  showLeumiChromeIphoneAlert: state => _isFlagOn(state, featureFlagNames.SHOW_LEUMI_CHROM_IPHONE_ALERT),
  showSupermarketMenuItem: state => _isFlagOn(state, featureFlagNames.SHOW_SUPERMARKET_MENU_ITEM),
};

const actions = {
  async init({ commit, dispatch }) {
    const [featureFlagTreatments, remoteVariablesTreatments, featureFlagJsonTreatments] = await Promise.all([
      featureFlag.getTreatments(_.values(featureFlagNames)),
      featureFlag.getTreatments(_.values(remoteVariables)),
      featureFlag.getTreatmentsAsJson(_.values(featureFlagJsonNames)),
    ]);
    const featuresTreatments = _.zipObject(_.values(featureFlagNames), featureFlagTreatments);
    const variablesTreatments = _.zipObject(_.values(remoteVariables), remoteVariablesTreatments);
    commit('setFeatureFlags', featuresTreatments);
    commit('setRemoteVariables', variablesTreatments);
    commit('setFeatureFlagsJsons', featureFlagJsonTreatments);
    await dispatch('initLocalOverrides');
    reactiveCashflow.setFeatureFlags(featuresTreatments);
  },
  logTreatments({ state }, customerId) {
    DDLogs.log('Customer feature flags', { customerId, featureFlagAttributes: state.featureFlags });
    const featureFlags = localStorage.getItem('feature_flags');
    if (!featureFlags || !_.isEqual(JSON.parse(featureFlags), state.featureFlags)) {
      Segment.trackUserGot('BalanceMoneyInsightInTrial_FeatureFlagValue', {
        enabled: state.featureFlags[featureFlagNames.BALANCE_MONEY_INSIGHT_IN_TRIAL] === 'on',
      });
      localStorage.setItem('feature_flags', JSON.stringify(state.featureFlags));
    }
  },
  async isFeatureFlagOn({ state }, flagName) {
    return (await featureFlag.getTreatment(flagName)) === 'on' || state.localOverrides[flagName] === 'on';
  },
  async initLocalOverrides({ dispatch, commit }) {
    const localOverrides = Object.entries(localStorage)
      .filter(([key]) => key.startsWith(LOCAL_STORAGE_OVERRIDE_PREFIX))
      .reduce((features, [key, value]) => {
        const flagName = key.replace(LOCAL_STORAGE_OVERRIDE_PREFIX, '');
        features[flagName] = value;
        return features;
      }, {});
    commit('setLocalOverrides', localOverrides);
    await dispatch('initFeaturesExtension');
  },
  initFeaturesExtension({ state }) {
    try {
      window.chrome?.runtime?.sendMessage(config.get().featureFlagExtensionId,
        { features: state.featureFlags, localOverrides: state.localOverrides });
    } catch (e) {
      DDLogs.error('Failed initializing features extension', e);
    }
  },
};

// mutations
const mutations = {
  setFeatureFlags(state, featureFlags) {
    state.featureFlags = featureFlags;
  },
  setRemoteVariables(state, variablesTreatments) {
    state.remoteVariables = variablesTreatments;
  },
  setLocalOverrides(state, localOverrides) {
    state.localOverrides = localOverrides;
  },
  setFeatureFlagsJsons(state, featureFlagsJsons) {
    state.featureFlagsJsons = featureFlagsJsons;
  },
};

function _isFlagOn(state, featureFlagName) {
  if (state.localOverrides[featureFlagName]) {
    return state.localOverrides[featureFlagName] === 'on';
  }
  return state.featureFlags[featureFlagName] === 'on';
}

function _getVariableValue(state, variableName) {
  return state.remoteVariables[variableName];
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
