import { sanitizeData } from '@riseupil/common-utils';
import { createSha256 } from '@/utils/hash';
import _ from 'lodash';
import config from './config';
import DDLogs from './DDLogs';
import anonymousSession from './anonymousSession';

let incognito = false;

const SENSITIVE_PARAM = 'inviteToken';

const SIGNED_UP_EVENT_NAME = 'Signed Up';
// This event is a reserved event in segment, meant to be sent once upon use signup to enable matching between anonymousId and userId in segment

function _getSegmentSourceId() {
  if (window.location.search.includes(SENSITIVE_PARAM)) {
    return config.get().segmentDisabledSourceId;
  }
  return config.get().segmentSourceId;
}

/* eslint-disable */
const Segment = {
  install(Vue, options) {
    (function () {
      var analytics = window.analytics = window.analytics || [];
      if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice."); else {
        analytics.invoked = !0;
        analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on"];
        analytics.factory = function (t) {
          return function () {
            var e = Array.prototype.slice.call(arguments);
            e.unshift(t);
            analytics.push(e);
            return analytics
          }
        };
        for (var t = 0; t < analytics.methods.length; t++) {
          var e = analytics.methods[t];
          analytics[e] = analytics.factory(e)
        }
        analytics.load = function (t, e) {
          var n = document.createElement("script");
          n.type = "text/javascript";
          n.async = !0;
          n.src = "https://cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js";
          var a = document.getElementsByTagName("script")[0];
          a.parentNode.insertBefore(n, a);
          analytics._loadOptions = e
        };
        analytics.SNIPPET_VERSION = "4.1.0";
        analytics.load(_getSegmentSourceId());
        analytics.page();
      }})();
  },

  setIncognito(isIncognito) {
    incognito = isIncognito;
  },

  identifyAnonymousUser(data) {
    const sanitizedData = sanitizeData(DDLogs, data, 'identifyAnonymousUser');
    analytics.identify(anonymousSession.getId(), sanitizedData);
    DDLogs.log('identify', sanitizedData);
  },

  identifyCustomer(customerId, data) {
    const sanitizedData = sanitizeData(DDLogs, data, 'identifyCustomer');
    analytics.identify(
      customerId,
      _.assign({ incognito }, sanitizedData),
      {
        integrations: {
          'Facebook Pixel': false
        }
      });
    DDLogs.log('identify', _.assign({}, { customerId }, sanitizedData));
  },

  trackUserSignedUp(eventPrefix, customerId) {
    const eventName = `${eventPrefix}${SIGNED_UP_EVENT_NAME}`;
    analytics.track(eventName, { userId: customerId});
    DDLogs.log(eventName, { customerId });
  },

  trackUserInteraction(eventName, data) {
    const sanitizedData = sanitizeData(DDLogs, data, eventName);
    analytics.track(eventName, _.assign({ incognito }, sanitizedData, {
        eventType: 'interaction',
      }),
      {
        integrations: {
          'Facebook Pixel': false
        }
      });
    DDLogs.log(eventName, sanitizedData);
  },

  trackUserInteractionToPixel(eventName, data) {
    const sanitizedData = sanitizeData(DDLogs, data, eventName);
    analytics.track(eventName, _.assign({ incognito }, sanitizedData, {
        eventType: 'interaction',
      }),
      {
        integrations: {
          'Facebook Pixel': true
        }
      });
    DDLogs.log(eventName, sanitizedData);
  },

  trackUserGot(eventName, data) {
    const sanitizedData = sanitizeData(DDLogs, data, eventName);
    analytics.track(eventName, _.assign({ incognito }, sanitizedData, {
        eventType: 'push',
      }),
      {
        integrations: {
          'Facebook Pixel': false
        }
      });
    DDLogs.log(eventName, sanitizedData);
  },
  trackUserGotToPixel(eventName, data, traits = {}) {
    const sanitizedData = sanitizeData(DDLogs, data, eventName);
    analytics.track(eventName, _.assign({ incognito }, sanitizedData, {
        eventType: 'push',
      }),
      {
        integrations: {
          'Facebook Pixel': true
        }
      },
      {
       traits: _hashTraits(traits),
      });
    DDLogs.log(eventName, sanitizedData);
  },

  reload() {
    analytics.load?.(_getSegmentSourceId());
  }
};

function _hashTraits(traits) {
  if (_.isEmpty(traits)) {
    return traits;
  }
  const traitsToHash = ['email', 'phone', 'name', 'firstName', 'lastName']
  return _.chain(traits)
    .toPairs()
    .map(([key, value]) => [key, traitsToHash.includes(key) ? createSha256(value) : value])
    .fromPairs()
    .value();
}

export default Segment;
